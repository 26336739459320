<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Edit Konfigurasi Skema Elemen",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Edit Konfigurasi Skema Elemen",
      items: [
        {
          text: "Konfigurasi",
          href: "/",
        },
        {
          text: "Konfigurasi Skema Elemen",
          href: "/konfigurasi/konfigurasi-skema",
        },
        {
          text: "Edit Konfigurasi Skema Elemen",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,
      
      // variabel referensi
      optionsElemenKompetensi: [],

      // variable Page
      id: this.$route.params.id,
      elemen_kompetensi_selected: "",
      urutan: null,
      id_konfig_skema_unit: null,
      data_edit: []
    };
  },
  mounted() {
    let self = this;
    self.getDataKonfigSkemaElemen();
    self.getDataElemenKompetensi();
  },
  methods: {
    getDataKonfigSkemaElemen(){
      let self = this;
      // get data konfig skema unit sertifikasi berdasarkan id
      var config_skema_sertifikasi_elemen = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/konfig-skema-elemen/" + self.id,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config_skema_sertifikasi_elemen)
      .then(function (response) {
        var response_data = response.data;
        var response_data_fix = response_data.data;
        if (response_data.meta.code == 200) {
          var data_edit = response_data_fix;
          self.elemen_kompetensi_selected = data_edit.elemen_kompetensi;
          self.urutan = data_edit.urutan;
          self.id_konfig_skema_unit = data_edit.config_skema_unit_id;
          Swal.close();
        } else {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.close();
      });
    },
    getDataElemenKompetensi(){
      let self = this;

      // load data menu parent
      var config_data_menu_parent = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-elemen-kompetensi",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config_data_menu_parent)
        .then(function (response) {
          var response_data = response.data;
          var response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            self.optionsElemenKompetensi = response.data.data.referensi;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "put",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/konfig-skema-elemen/" + self.id,
        data: {
          id: self.id,
          elemen_kom_id: self.elemen_kompetensi_selected?.id_elemen_kompetensi,
          elemen_kom_kode: self.elemen_kompetensi_selected?.kode_elemen_kompetensi,
          elemen_kom_nama: self.elemen_kompetensi_selected?.nama_elemen_kompetensi,
          urutan: self.urutan
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config_store_data)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman master konfig skema elemen segera",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              self.$router.push({ name: "all-konfigurasi_skema_elemen", params: { id_konfig_skema_unit: self.id_konfig_skema_unit }  });
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data.error;
          Swal.close();
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div v-if="axiosCatchError">
                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' ||
                  axiosCatchError instanceof String
                  ">
                  {{ axiosCatchError }}
                </div>
                <div class="alert alert-danger" role="alert" v-else>
                  <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                    {{ errorArray }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Elemen Kompetensi</label>
                    <v-select 
                      :options="optionsElemenKompetensi" 
                      label="nama_elemen_kompetensi"
                      v-model="elemen_kompetensi_selected"
                      placeholder="Pilih Elemen Kompetensi">
                    </v-select>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Urutan</label>
                    <input type="number" v-model="urutan" class="form-control" min="1">
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-12 col-md-6">
                  <div class="text-end">
                    <b-button type="reset" class="m-1" variant="danger"><i class="fas fa-redo-alt"></i> Reset</b-button>
                    <b-button type="submit" variant="primary" class="m-1"><i class="fa fa-save"></i> Simpan
                      Konfig Skema Seritifikasi</b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout></template>
